import Api from '@/services/Index';

const addressQuery = async (query) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/admin/search-address', query);
}

export default {
    addressQuery
}
