<template>
    <ValidationObserver ref="formReservationModalValidate">
      <div class="d-flex flex-column w-100">
        <div class="d-flex w-100 position-relative">
            <div class="flex-grow-1 position-relative">
                <ValidationProvider name="query" :rules="required?'required|min_length_10':'min_length_10'" v-slot="{valid,errors}">
                    <b-form-input
                        v-model="query"
                        :state="state"
                        @focus="showList = true"
                        placeholder="Search Address"
                        class="mb-2"
                        @click="openList"
                        @keyup.enter="onInput"
                    />
                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                </ValidationProvider>
                <b-list-group
                    v-if="showList && items.length > 0"
                    class="position-absolute w-100 overflow-auto bg-white border z-index-1"
                    style="max-height: 300px; top: 100%; left: 0;"
                >
                    <b-list-group-item
                        v-for="(item, index) in items"
                        :key="index"
                        @click="selectItem(item)"
                    >
                        <b-form-radio
                            v-model="radioButton"
                            :name="item.full_address + index"
                            :value="item.full_address"
                        >
                            {{ item.full_address }}
                        </b-form-radio>
                    </b-list-group-item>
                </b-list-group>
                <b-list-group
                    v-if="!items.length && showNotData"
                    class="position-absolute w-100 overflow-auto bg-white border z-index-1"
                    style="max-height: 300px; top: 100%; left: 0;"
                >
                    <b-list-group-item
                    >
                       Address Not Found.!
                    </b-list-group-item>
                </b-list-group>
            </div>
            <b-button variant="success" disabled v-if="loading" class="ml-2">
                <b-spinner
                    small
                    type="grow"
                    label="Loading..."
                ></b-spinner>
            </b-button>
            <b-button variant="success" @click="onInput(query)" v-else   class="ml-2  align-self-start">
               <span > Search</span>
            </b-button>

        </div>
    </div>
    </ValidationObserver>
</template>

<script>
import AdminAddressServices from "@/services/AdminAddressServices";
import qs from "qs";

export default {
    props:{
        state:{},
        required:{
            type:Boolean,
            default: true
        }
    },
    data() {
        return {
            radioButton: null,
            selected: {
                city_id: null,
                district_id: null,
                full_address: null,
                street_name: null,
                street_number: null,
                zip_code: null,
            },
            query: "",
            items: [],
            loading: false,
            showList: false,
            showButton: true,
            showNotData:false
        };
    },
    watch:{
      query(newVal, oldVal) {
          if (newVal !== oldVal) {
              this.showNotData=false
          }
      }
    },
    methods: {
      async onInput() {
          const isValid = await this.$refs.formReservationModalValidate.validate();
            if (isValid && this.query.length >= 10) {
                this.items=[]
                this.search(this.query);
            }else {
                this.items = [];
                this.showList = false;
                this.showButton=false
            }
        },
        openList() {
            if (this.items.length > 0) {
                this.showList = true;
            }
        },
        selectItem(item) {
            this.query = item.full_address;
            this.selected = item;
            this.$emit('selectedAddress', item);
            this.radioButton = item.full_address;
            this.showList = false;
        },
        search(key) {
            this.loading = true;
            const config = {
                params: {
                    query: key,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            AdminAddressServices.addressQuery(config)
                .then((response) => {
                    this.items = response.data.data;
                    this.showList = true;

                    if (this.items.length === 1) {
                        this.selectItem(this.items[0]);
                    }
                })
                .catch((error) => {
                    this.showErrors(error);
                    this.items = [];
                })
                .finally(() => {
                    this.loading = false;
                    !this.items.length?this.showNotData=true:this.showNotData=false
                });
        },
    },
};
</script>

<style>
html,
body {
    margin: 0;
    height: 100%;
}

.z-index-1 {
    z-index: 1000;
}
</style>
