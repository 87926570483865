<template>
    <div style="display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 60px); padding: 30px;">
        <b-card style="width: 100%; max-width: 1100px; margin: 0 auto;">
            <div class="text-center mb-2">
                <loading v-show="pageLoading" />
                <div v-show="!pageLoading">
                    <b-row class="mt-5" style="padding-left: 15px; padding-right: 15px;">
                        <!-- Logo Section -->
                        <b-col cols="12" class="d-flex justify-content-center align-item-center mb-5">
                            <div class="logo-wrapper">
                                <img src="../../../assets/img/island-candos-logo.png" width="100%" />
                            </div>
                        </b-col>
                        <!-- Header Text -->
                        <b-col cols="12" class="d-flex justify-content-center align-item-center mb-5">
                            <h2 class="text-center">{{ $t('room_reservation_system') }}</h2>
                        </b-col>
                    </b-row>

                    <!-- Form Section -->
                    <ValidationObserver ref="formReservationModalValidate">
                        <b-card :sub-title="$t('personal_info')">
                            <b-row>
<!--                                <b-col xs="12" sm="6">-->
<!--                                    <ValidationProvider name="type_status" rules="required" v-slot="{errors}">-->
<!--                                        <b-form-group :label="$t('are_you_a_bau_student_or_staff')">-->
<!--                                            <b-input-group-->
<!--                                                class="mb-3 flex-nowrap"-->
<!--                                                prepend="*"-->
<!--                                            >-->
<!--                                            <YesNoSelectbox class="w-100" v-model="formAddress.type_status"  />-->
<!--                                            </b-input-group>-->
<!--                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>-->

<!--                                        </b-form-group>-->
<!--                                    </ValidationProvider>-->
<!--                                </b-col>-->


                            </b-row>
                            <b-row>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group   :label="$t('name')">
                                            <b-input-group
                                                class="mb-3"
                                                prepend="*"
                                            >
                                                <b-form-input v-model="formAddress.name" :class="errors[0] ? 'is-invalid' : ''" />

                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="surname" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('surname')">
                                            <b-input-group
                                                class="mb-3"
                                                prepend="*"
                                            >
                                            <b-form-input v-model="formAddress.surname" :class="errors[0] ? 'is-invalid' : ''" />
                                            </b-input-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="gender" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('gender')">
                                            <b-input-group
                                                class="mb-3"
                                                prepend="*"
                                            >
                                            <b-form-input v-model="formData.gender" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            </b-input-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="email" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('email')">
                                            <b-input-group
                                                class="mb-3"
                                                prepend="*"
                                            >
                                            <b-form-input v-model="formData.email" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            </b-input-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="mobile_tel_country_code" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('country_code')">
                                            <b-input-group
                                                class="mb-3 flex-nowrap"
                                                prepend="*"
                                            >
                                                <multiselect
                                                    v-model="formAddress.mobile_tel_country_code"
                                                    :options="countryCodeData"
                                                    track-by="text"
                                                    label="text"
                                                    @input="selectedCountryCode"
                                                    :searchable="true"
                                                />
                                            </b-input-group>

                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="mobile_tel" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('phone_number')">
                                            <b-input-group
                                                class="mb-3 flex-nowrap"
                                                prepend="*"
                                            >
                                            <b-form-input v-mask="mask" v-model="formAddress.mobile_tel" :class="errors[0] ? 'is-invalid' : ''" />
                                            </b-input-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="passport_country_id" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('passport_country')">
                                            <b-input-group
                                                class="mb-3
                                                 flex-nowrap"
                                                prepend="*"
                                            >
                                            <country-selectbox class="w-100" v-model="formAddress.passport_country_id" :public="true" />
                                            </b-input-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="passport_number" rules="required"  v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('passport_number')"
                                        >
                                            <b-input-group
                                                class="mb-3
                                                 flex-nowrap"
                                                prepend="*"
                                            >
                                            <b-form-input v-model="formAddress.passport_number" :class="errors[0] ? 'is-invalid' : ''" />
                                            </b-input-group>
                                        </b-form-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>

                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-card>

                        <!-- Address Information -->
                        <b-card :sub-title="$t('us_address_optional')" class="mt-3">
                            <b-row>
                                <b-col cols="12"
                                       class="mb-5">
                                    <AddressSearchComponent v-model="searchAddress"  @selectedAddress="selected" :required="false"/>
                                </b-col>
                                <b-col xs="12" sm="4">


                                    <ValidationProvider name="address_city_id" rules="" v-slot="{ valid, errors }">
                                        <b-form-group>
                                            <label>{{ $t('city_name') }}</label>
                                            <city-selectbox v-model="formAddress.address_city_id" :validateError="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="address_district_id" rules="" v-slot="{ valid, errors }">
                                        <b-form-group>
                                            <label>{{ $t('city') }}</label>
                                            <district-outline-selectbox v-model="formAddress.address_district_id" :city_id="formAddress.address_city_id" :validateError="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="address_zip_code" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('zip_code')">
                                            <b-form-input v-model="formAddress.address_zip_code" :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col xs="12" sm="4">
                                        <b-form-group :label="$t('street')">
                                            <b-form-input v-model="formAddress.street" />
                                        </b-form-group>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <b-form-group :label="$t('apartment')">
                                        <b-form-input v-model="formAddress.apartment" />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>

                        <!-- Reservation Information -->
                        <b-card :sub-title="$t('reservation_information')" class="mt-3">
                            <b-row>

                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="check_in_date" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('check_in_date')">
                                            <b-form-input v-model="formData.check_in_date" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <div class="label-as-input-div"><label>Check In Time</label><div>11:00 - 17:00</div></div>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="check_out_date" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('check_out_date')">
                                            <b-form-input v-model="formData.check_out_date" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <div class="label-as-input-div"><label>Check Out Time</label><div>09:00 - 17:00</div></div>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="housing_building_name" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group label="Building / Floor">
                                            <b-form-input :value="formData.housing_building_name+' / '+formData.housing_floor" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="housing_room_name" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('room')">
                                            <b-form-input v-model="room" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <b-form-group :label="$t('bed')">
                                        <b-form-input :value="formData.bed_name+' - '+formData.type_name" readonly/>
                                    </b-form-group>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="fee" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('monthly_fee')">
                                            <b-form-input :value="currencyFormat(formData.fee)" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <b-form-group :label="$t('duration')">
                                        <b-form-input :value="formData.duration" readonly/>
                                    </b-form-group>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="total" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('total_amount')">
                                            <b-form-input :value="currencyFormat(formData.total_amount)" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-card>
                    </ValidationObserver>

                    <!-- Submit Button -->
                    <div class="d-flex justify-content-center mt-3">
                        <b-button @click="openApprovedButton()">
                            {{ $t('submit') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </b-card>
        <CommonModal  ref="approvedText">
            <template v-slot:CommonModalTitle>
                <div>{{$t('approve')}}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <ApprovedText v-if="formProgress==='approved_text'" :security-deposit-fee="formData.security_deposit_fee" @approvedButtonClick="saveReservationForm"/>
                </div>
            </template>
        </CommonModal>
    </div>

</template>

<script>
    // Component
	import CitySelectbox from "@/components/interactive-fields/CitySelectbox";
	import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";
	import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
    import HousingReservationFormService from '@/services/HousingReservationFormService'
    import {mapGetters} from "vuex";
    // Pages
    import Loading from '@/components/elements/Loading'

    import {setSuccessRoutePage} from "@/modules/housingReservationForm/router/middleware"
    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import countryCodeInputData from "./countryCodeInputData"
    import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox.vue";
    import ApprovedText from "@/modules/housingReservationForm/pages/ApprovedText.vue";
    import AddressSearchComponent from "@/components/interactive-fields/AddressSearchComponent.vue";
    export default {
        components: {
            AddressSearchComponent,
            ApprovedText,
            YesNoSelectbox,
            ValidationProvider,
            ValidationObserver,
            Loading,
            CitySelectbox,
            DistrictOutlineSelectbox,
            CountrySelectbox
        },
        metaInfo() {
        return {
            title: this.$t("room_reservation_form"),
        };
    },
        data() {
            return {
                searchAddress:null,
                formProgress:null,
                type_status:false,
                pageLoading: false,
                mask: '',
                countryCodeInputData,
                formAddress: {
                    address_city_id:null,
                    address_district_id:null,
                    address_zip_code:null,
                    apartment:null,
                    street:null
                },
            }
        },
        computed: {
        countryCodeData() {
                let countryCode = []
                this.countryCodeInputData.forEach(itm => {
                    countryCode.push({
                        text: itm.text,
                        value: itm.value,
                        code: itm.maxlength ?? null
                    })
                })
                return countryCode
            },
            room(){
                return this.formData.housing_room_name+' - '+this.formData.room_type_name;
            },
            ...mapGetters({
             formData: "housingReservationForm/formData"
            })
        },

        methods: {
        selectedCountryCode(value) {
            const find = this.countryCodeInputData.filter(a => a.value == value.value)
            if (!find) {
                return;
            }
            const maxPhoneNumberLength = parseInt(find[0].maxlength)
            this.mask = `${value.value} ${'#'.repeat(maxPhoneNumberLength)}`;
        },

        async openApprovedButton(){
            const isValid= await this.$refs.formReservationModalValidate.validate();
            if (isValid){
                this.formProgress='approved_text';
                this.$refs.approvedText.$refs.commonModal.show()
            }

        },
            selected(event){
             this.formAddress.address_city_id=event.city_id
            this.formAddress.address_district_id=event.district_id
            this.formAddress.address_zip_code=event.zip_code
            this.formAddress.street=event.street_name
            this.formAddress.apartment=event.street_number
            },
        async saveReservationForm() {
            const isValid = await this.$refs.formReservationModalValidate.validate();
            if(!isValid){
                return
            }

            this.pageLoading = false;
            this.formData.housing_bed_id=this.formData.id
            delete this.formData.type
            if (!this.formData.type_status) {
                this.formAddress.type='other'
            }else{
                this.formAddress.type=this.formData.bau_type
            }
            let formData = {
                ...this.formAddress,
                ...this.formData
            };
                try {
                    const response = await HousingReservationFormService.save(formData);
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('createFormSuccess', true);
                    await  this.$store.dispatch('housingReservationForm/deleteFormData')
                    setSuccessRoutePage(true)
                    this.$router.replace('/housing/reservation/form/success');
                } catch (e) {
                    this.showErrors(e, this.$refs.formReservationModalValidate);
                } finally {
                    this.pageLoading = false;
                }
    },
},

    }
</script>
